import * as React from 'react';
import Clipboard from 'simple-react-clipboard';
import { Box, type BoxT } from '../Box';
import { TooltipControlled } from '../Tooltip';

export interface CopyT extends Omit<BoxT, 'children'> {
  id: string;
  src: string;
  showSuccessTooltip?: boolean;
  tooltip?: string;
  children: (arg0: { copy: () => void }) => React.ReactNode;
}

export const Copy = ({
  id,
  src,
  showSuccessTooltip = true,
  tooltip = 'Copied!',
  children,
  ...rest
}: CopyT) => {
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false);
      }, 2000);
    }
  }, [show]);

  return (
    <>
      <TooltipControlled description={tooltip} show={show} referenceId={id} />
      <Box id={id} {...rest}>
        <Clipboard
          onSuccess={() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            showSuccessTooltip && setShow(true);
          }}
          text={src}
          render={children}
        />
      </Box>
    </>
  );
};
