export function getCoreURL(region: 'eu' | 'us'): string {
  let coreURL;
  if (region === 'eu') {
    coreURL = process.env.EU_CORE_URL ?? process.env.NEXT_PUBLIC_EU_CORE_URL;
  } else {
    coreURL = process.env.US_CORE_URL ?? process.env.NEXT_PUBLIC_US_CORE_URL;
  }
  if (!coreURL) {
    throw new Error(`No core URL for region ${region}`);
  }
  return coreURL;
}
