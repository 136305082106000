const noop = () => {};

/**
 * Special method for calling the function only once: either when it can be called (we have OneTrust in place)
 * or calling it just as soon as we have OneTrust set
 * @param {Function} cb function to be called
 * @returns {Function} clean-up function in case we want to use this method in effects
 */
const executeOnce = (cb: typeof noop): typeof noop => {
  if (typeof window === 'undefined') {
    return noop;
  }

  if (window.OneTrust) {
    cb();

    return noop;
  }

  window.addEventListener('OneTrustBannerLoaded', cb, {
    once: true,
  });

  return () => {
    window.removeEventListener('OneTrustBannerLoaded', cb);
  };
};

/**
 * Opens Cookie preference center where you can change your cookie preferences
 */
export const openPreferenceCenter = () =>
  executeOnce(() => window.OneTrust?.ToggleInfoDisplay());

/**
 * Closes cookie banner and sets default cookie categories (only strictly necessary by default)
 */
export const closeCookieBanner = () =>
  executeOnce(() => window.OneTrust?.Close());
