'use client';
import { createNavigation } from 'next-intl/navigation';
import { routing } from './routing';

const result = createNavigation(routing);

// Lightweight wrappers around Next.js' navigation APIs
// that will consider the routing configuration
export const Link = result.Link;
export const usePathname = result.usePathname;
export const useRouter = result.useRouter;
