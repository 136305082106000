import * as React from 'react';
import { Box, Copy } from '@mentimeter/ragnar-ui';

interface Props {
  heading: string;
  textAlign?: string;
  as: React.ComponentType<any>;
}

// Note: this constant is to compensate for the sticky menu.
const STICKY_OFFSET = 100;

export function slugify(text: string): string {
  return text
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export const AnchorHeading = ({ heading, textAlign, as: Wrapper }: Props) => {
  const [anchorRef, setAnchorRef] = React.useState('');
  const id = slugify(heading);
  React.useEffect(() => {
    setAnchorRef(`${window.location.origin}${window.location.pathname}#${id}`);
  }, [id]);

  return (
    <>
      <Box
        height={STICKY_OFFSET}
        mt={-STICKY_OFFSET}
        id={id}
        extend={() => ({ pointerEvents: 'none' })}
      />
      <Copy src={anchorRef} id={`${id}-reference`}>
        {({ copy }) => (
          <Wrapper textAlign={textAlign} onClick={copy}>
            {heading}
          </Wrapper>
        )}
      </Copy>
    </>
  );
};
