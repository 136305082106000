import { useReferral } from '@mentimeter/auth';

/**
 * Remaps the href attribute to include the referral query param if
 * it points to /auth/signup page.
 *
 * During pt-PR split test this will also add some meta data in onClick.
 */
export function useReferralPropMapper<
  PropsT extends { href?: string | undefined },
>(): (arg0: PropsT) => PropsT {
  const referral = useReferral();

  return (props) => {
    if (
      props.href?.startsWith('/sign-up') ||
      props.href?.startsWith('/auth/signup') ||
      props.href?.startsWith('/signup')
    ) {
      const signupHref = `${props.href}${
        props.href.includes('?') ? '&' : '?'
      }referral=${referral}`;

      return {
        ...props,
        href: signupHref,
      };
    }

    return props;
  };
}
