/* eslint-disable menti-react/filename-convention--jsx */
'use client';
import * as React from 'react';
import { Box, type BoxT, Text, type TextT } from '@mentimeter/ragnar-ui';
import { Link } from '@mentimeter/ragnar-ui';
import { slugify } from 'src/components/AnchorHeading';
import { getTextContent } from 'src/cms/DocumentRenderer';
import { LinkIcon } from '@mentimeter/ragnar-visuals';

interface HeadingT extends TextT {
  useAnchors?: boolean;
}

const Heading = (props: HeadingT) => {
  const { children, useAnchors } = props;
  if (!children) {
    return null;
  }

  const id = slugify(getTextContent(children));

  const generalStyles: TextT = {
    as: 'h2',
    color: 'text',
    fontFamily: 'heading',
    lineHeight: 'heading',
    fontSize: [5, 6, 7],
    mb: ['space2', null, 'space4'],
  };

  if (useAnchors) {
    return (
      <Text
        {...generalStyles}
        id={id}
        extend={() => ({
          scrollMarginTop: '130px',
          '> .heading-anchor': {
            display: 'none',
          },
          ':hover': {
            '> .heading-anchor': {
              display: 'initial',
            },
          },
        })}
        {...props}
      >
        {children}
        <Link
          lineHeight="none"
          underline={false}
          aria-hidden="true"
          className="heading-anchor"
          href={`#${id}`}
          mx="space2"
        >
          <LinkIcon size={16} />
        </Link>
      </Text>
    );
  }

  return (
    <Text
      {...generalStyles}
      id={id}
      extend={() => ({ scrollMarginTop: '130px' })}
      {...props}
    />
  );
};

// Use as the main heading on pages without a Hero
export const H1 = (props: TextT) => (
  <Heading as="h1" fontSize={['200', 7, 7, '300']} {...props} />
);

// Use in longer text content, e.g blog posts
export const H2 = (props: TextT) => (
  <Heading
    as="h2"
    mb={['space4', 'space6']}
    fontSize={['175', '200', '225', '225']}
    {...props}
  />
);

// Use in longer text content, e.g blog posts and secondary headings in blocks
export const H3 = (props: HeadingT) => (
  <Heading
    as="h3"
    fontSize={['125', null, null, '175']}
    useAnchors={false}
    {...props}
  />
);

// Use in longer text content, e.g blog posts
export const H4 = (props: TextT) => (
  <Heading as="h4" fontSize="125" {...props} />
);

// Use below Headings
export const Subheading = (props: TextT) => (
  <Text as="p" fontSize={['125', null, null, '150']} {...props} />
);

export const P = (props: TextT) => (
  <Text as="p" fontSize={['100', '125']} mb={['space2', 'space8']} {...props} />
);

export const Bold = (props: TextT) => (
  <Text
    as="span"
    fontWeight="semiBold"
    fontSize="inherit"
    lineHeight="inherit"
    color="inherit"
    {...props}
  />
);

export const Italic = (props: TextT) => (
  <Text
    as="span"
    fontStyle="italic"
    fontSize="inherit"
    lineHeight="inherit"
    color="inherit"
    {...props}
  />
);

export const Meta = (props: TextT) => (
  <Text
    as="p"
    color="textWeaker"
    fontSize="87.5"
    lineHeight="body"
    {...props}
  />
);

export const Badge = (props: TextT) => (
  <Text
    color="primary"
    fontSize="100"
    textTransform="uppercase"
    {...props}
    extend={() => ({
      letterSpacing: '4px',
    })}
  />
);

interface ParagraphOrderedListItemT extends BoxT {
  index: number;
}

export const ParagraphOrderedListItem = ({
  index,
  children,
  ...rest
}: ParagraphOrderedListItemT) => (
  <Box
    as="li"
    flexDirection="row"
    alignItems="stretch"
    mb="space4"
    extend={() => ({
      '&:last-child': {
        marginBottom: 'space0',
      },
    })}
    {...rest}
  >
    <Text fontSize="125" fontWeight="semiBold">
      §{index}
    </Text>
    {children}
  </Box>
);
