import * as React from 'react';
import {
  Box,
  Text,
  PopoverContainer,
  PopoverTrigger,
  PopoverRoot,
  PopoverBody,
  Link,
} from '@mentimeter/ragnar-ui';
import type { LinkT } from '@mentimeter/ragnar-ui';
import { ChevronDownIcon } from '@mentimeter/ragnar-visuals';
import { usePathname } from '@mentimeter/next-navigation';
import { isMenuMultiAction, type MenuAction } from '.';

interface Props {
  action: MenuAction;
  show: boolean;
  setSelectedMenu: React.Dispatch<React.SetStateAction<string | null>>;
  overviewLabel: string | undefined;
  openDropdownLabel?: string;
}

const Separator = () => (
  <Box
    as="hr"
    width="100%"
    my="space4"
    borderWidth="0"
    height="2px"
    bg="border"
  />
);

interface MenuLinkT extends LinkT {
  active?: boolean;
}

const MenuLink = ({ children, active = false, ...linkProps }: MenuLinkT) => (
  <Box as="li" mx="space2">
    <Link underline={false} color={active ? 'brand' : 'text'} {...linkProps}>
      {children}
    </Link>
  </Box>
);

interface SubActionsProps {
  action: MenuAction;
  overviewLabel: string;
}

const Actions = ({ action, overviewLabel }: SubActionsProps) => {
  const pathname = usePathname();

  if (!action) return null;

  if (isMenuMultiAction(action)) {
    return (
      <Box flexDirection="row" gap="space4" alignItems="stretch">
        {action.subActions?.map((row) => (
          <Box flexDirection="column" key={row.name} minWidth="200px">
            <Text fontWeight="semiBold" color="inherit" mb="space4" mx="space2">
              {row.name}
            </Text>
            <Actions action={row} overviewLabel={overviewLabel} />
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Box
      flexDirection="column"
      as="ul"
      width={1}
      flex="1 0"
      justifyContent="space-between"
    >
      <Box gap="space4" width={1}>
        {action.subActions?.map(({ href, name, ...linkProps }) => (
          <MenuLink
            key={name}
            {...linkProps}
            href={href}
            active={pathname === href}
            aria-current={pathname === href ? 'page' : undefined}
            fontSize="100"
          >
            {name}
          </MenuLink>
        ))}
      </Box>
      {action.href && (
        <Box width={1}>
          <Separator />
          <MenuLink
            active={pathname === action.href}
            aria-current={pathname === action.href ? 'page' : undefined}
            href={action.href}
          >
            {overviewLabel || action.name}
          </MenuLink>
        </Box>
      )}
    </Box>
  );
};

export const MenuDropdown = ({
  action,
  setSelectedMenu,
  show,
  overviewLabel = 'Overview',
  openDropdownLabel = 'Open dropdown',
}: Props) => {
  return (
    <Box
      onMouseEnter={() => setSelectedMenu(action.referenceId || null)}
      onMouseLeave={() => setSelectedMenu(null)}
      alignItems="center"
      py="space4"
    >
      <PopoverRoot
        open={show}
        onOpenChange={(open) =>
          setSelectedMenu((open && action.referenceId) || null)
        }
      >
        <PopoverTrigger>
          <Text
            aria-label={openDropdownLabel}
            aria-expanded={show}
            fontWeight="semiBold"
            alignItems="center"
            color="text"
            extend={() => ({ cursor: 'pointer' })}
          >
            {action.name}
            <Box
              display="inline-flex"
              aria-hidden="true"
              extend={({ theme }) => ({
                verticalAlign: 'middle',
                marginLeft: '2px',
                transition: `transform ${theme.durations[1]}s ease`,
                transform: show ? 'rotate(-180deg)' : 'rotate(0)',
              })}
            >
              <ChevronDownIcon color="currentColor" size={0} />
            </Box>
          </Text>
        </PopoverTrigger>
        <PopoverContainer aria-label={openDropdownLabel}>
          <PopoverBody p="space4" my="space0">
            <Actions action={action} overviewLabel={overviewLabel} />
          </PopoverBody>
        </PopoverContainer>
      </PopoverRoot>
    </Box>
  );
};
