import { useContext } from 'react';
import { DevelopmentToolsContext } from '../context';

export function useDevelopmentToolsModal() {
  const developmentToolsContext = useContext(DevelopmentToolsContext);

  return {
    isDevelopmentToolsModalOpen: developmentToolsContext.isModalOpen,
    openDevelopmentToolsModal: developmentToolsContext.openModal,
    tryOpenDevelopmentToolsModal() {
      if (developmentToolsContext.disabled) return false;

      developmentToolsContext.openModal();
      return true;
    },
    closeDevelopmentToolsModal: developmentToolsContext.closeModal,
  };
}
