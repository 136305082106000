import * as React from 'react';
import { Box, Text, Link } from '@mentimeter/ragnar-ui';
import { H1 } from '../ui/typography';

const NoScript = (): React.ReactElement<'noscript'> => {
  return (
    <noscript style={{ width: '100%' }}>
      <Box zIndex={11}>
        <Box
          width="100%"
          justifyContent="center"
          alignItems="center"
          p="space4"
          bg="negative"
        >
          <H1
            fontWeight="semiBold"
            lineHeight="heading"
            textAlign="center"
            color="bg"
          >
            Please turn on Javascript
          </H1>
          <Text textAlign="center" my="space1" color="bg">
            Your browser either doesn&apos;t support Javascript, or you&apos;ve
            turned it off.
          </Text>
          <Text textAlign="center" color="bg">
            To use Mentimeter, please make sure to turn Javascript on.&nbsp;
          </Text>
          <Link
            color="bg"
            textAlign="center"
            href="https://www.enable-javascript.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            How do I enable Javascript?
          </Link>
        </Box>
      </Box>
    </noscript>
  );
};

export default NoScript;
