'use client';
import * as React from 'react';
import { Box, Text } from '@mentimeter/ragnar-ui';
import { Link } from '../ui/actions';
import Header from '../ui/header';
import Footer from './Footer';
import OutdatedBrowser from './OutdatedBrowser';
import NoScript from './NoScript';

interface Props {
  headerElement?: React.ReactNode;
  footerElement?: React.ReactNode;
  fullHeight?: boolean;
  children: React.ReactNode;
}

function Page({
  fullHeight,
  headerElement = <Header />,
  footerElement = <Footer />,
  children,
}: Props) {
  const [isOutdatedBrowser, setIsOutdatedBrowser] = React.useState(false);

  React.useEffect(() => {
    // set these flags on client
    setIsOutdatedBrowser(OutdatedBrowser(navigator.userAgent));
  }, []);

  if (isOutdatedBrowser) {
    return (
      <Text m="auto" p={6} textAlign="center">
        Hi, we&apos;re sorry to let you know that Mentimeter doesn&apos;t
        support your browser. Please
        <Link
          href="http://outdatedbrowser.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          update your browser
        </Link>
        , or try using another one.
      </Text>
    );
  }

  return (
    <>
      <NoScript />
      {fullHeight ? (
        <Box
          minHeight="100vh"
          flexDirection="column"
          alignItems="stretch"
          justifyContent="stretch"
        >
          {headerElement}
          <Box
            as="main"
            flex="1"
            flexDirection="column"
            alignItems="stretch"
            justifyContent="stretch"
          >
            {children}
          </Box>

          {footerElement}
        </Box>
      ) : (
        <>
          {headerElement}
          <main>{children}</main>
          {footerElement}
        </>
      )}
    </>
  );
}

export default Page;
