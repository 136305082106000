/*
 * Check browser
 */
const OutdatedBrowser = (nAgt: string) => {
  if (nAgt) {
    // IE 10 or less
    // EDGE less than 15
    const msie = nAgt.indexOf('MSIE');
    const edge = nAgt.indexOf('Edge');
    const edgeVersion = parseInt(nAgt.substring(nAgt.lastIndexOf('/') + 1), 10);
    if (msie > 0) {
      return true;
    }
    if (edge > 0 && edgeVersion < 15) {
      return true;
    }
  }
  return false;
};

export default OutdatedBrowser;
