import * as React from 'react';
import DeviceContext from './createContext';
import type { Context, MatchCriteriaT } from './types';
import { useMatch } from './use-match';

interface Props {
  render?: (arg0: Context) => React.ReactNode;
}

const DeviceComponent = ({ render }: Props) =>
  render ? (
    <DeviceContext.Consumer>
      {(context) => render(context)}
    </DeviceContext.Consumer>
  ) : null;

const Match = ({
  children,
  ...matchCriteria
}: React.PropsWithChildren<MatchCriteriaT>) => {
  const shouldRender = useMatch(matchCriteria);
  return shouldRender ? children : null;
};

const Device = Object.assign(DeviceComponent, { Match });

export default Device;
