import { defineRouting } from 'next-intl/routing';
import { DEFAULT_LOCALE, LOCALES } from 'src/constants.cjs';
import { USER_LOCALE_STORAGE_KEY } from '@mentimeter/i18n/constants';

export const routing = defineRouting({
  // A list of all locales that are supported
  locales: LOCALES,

  // Used when no locale matches
  defaultLocale: DEFAULT_LOCALE,
  localePrefix: 'as-needed', // Ensure locale prefix is used as needed
  localeCookie: {
    name: USER_LOCALE_STORAGE_KEY,
  },
  alternateLinks: false,
});
