import { useContext } from 'react';
import {
  I18nContext,
  useTranslation as i18NUseTranslation,
} from 'react-i18next';
import { useLocale as nextIntlUseLocale } from 'next-intl';

export const useLocale = () => {
  try {
    const i18nContext = useContext(I18nContext); // Check if react-i18next context is available

    if (i18nContext) {
      const { i18n } = i18NUseTranslation();

      return i18n.language;
    }
  } catch (_) {
    // react-i18next context is not found, falling back to next-intl
  }

  return nextIntlUseLocale();
};
