import {
  blue500,
  blueDark500,
  gray200,
  greenDark500,
  greenLight500,
  pink500,
  pinkLight500,
  purpleLight500,
  whiteAlpha500,
  whiteAlpha800,
  whiteAlpha900,
  whiteBase,
  purpleDark800,
  themes,
  whiteAlpha1000,
} from '@mentimeter/ragnar-colors';
import type { Colors } from '@mentimeter/ragnar-dsc';
import type { IMoodFields } from 'types/generated/contentful';
import type { ContentfulColorsT } from '../../shared/cms/content-type-types';

export interface ThemeT {
  theme?: Pick<IMoodFields, 'colors'> | undefined;
}
//Make sure that only patterns with bg images include the "pattern" name in the moods
export const CONTENTFUL_THEMES: {
  [key in ContentfulColorsT]: { colors: Colors };
} = {
  dark: { colors: { ...themes.dark.colors, bg: blueDark500 } },
  brand: {
    colors: {
      ...themes.primary.colors,
      borderPrimaryWeakest: whiteAlpha500,
    },
  },
  yellow: {
    colors: {
      ...themes.notice.colors,
      borderPrimaryWeakest: whiteAlpha900,
    },
  },
  green: {
    colors: {
      ...themes.positive.colors,
      borderPrimaryWeakest: whiteAlpha500,
    },
  },
  greenLight: {
    colors: {
      ...themes.neutral.colors,
      bg: greenLight500,
      borderPrimaryWeakest: whiteAlpha800,
    },
  },
  greenDark: {
    colors: {
      ...themes.primary.colors,
      bg: greenDark500,
      borderPrimaryWeakest: whiteAlpha500,
    },
  },
  pink: {
    colors: {
      ...themes.notice.colors,
      bg: pink500,
      borderPrimaryWeakest: whiteAlpha800,
    },
  },
  pinkLight: {
    colors: {
      ...themes.neutral.colors,
      bg: pinkLight500,
      borderPrimaryWeakest: whiteAlpha900,
    },
  },
  purple: {
    colors: {
      ...themes.info.colors,
      borderPrimaryWeakest: whiteAlpha500,
      text: whiteBase,
      textWeak: whiteAlpha1000,
    },
  },
  purpleLight: {
    colors: {
      ...themes.neutral.colors,
      bg: purpleLight500,
      borderPrimaryWeakest: whiteAlpha800,
    },
  },
  purpleDark: {
    colors: {
      ...themes.info.colors,
      borderPrimaryWeakest: whiteAlpha500,
      bg: purpleDark800,
      text: whiteBase,
      textWeak: whiteAlpha1000,
    },
  },
  plain: {
    colors: {
      ...themes.neutral.colors,
      bg: gray200,
      primary: blue500,
    },
  },
  shade: {
    colors: {
      ...themes.neutral.colors,
      bg: gray200,
      primary: blue500,
    },
  },
  balloon: {
    colors: {
      ...themes.neutral.colors,
      bg: '#ffd6db',
      borderPrimaryWeakest: whiteAlpha900,
    },
  },

  pattern: { ...themes.positive },
  patternGreen: { ...themes.positive },
  patternGreenCTA: { ...themes.positive },
  patternYellowCTA: { ...themes.positive },
  patternPurpleCTA: { ...themes.positive },
};
