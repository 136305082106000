import * as React from 'react';
import type { PopoverContentProps } from './PopoverContent';
import { PopoverContent } from './PopoverContent';
import { PopoverPortal } from './PopoverPortal';
import { PopoverArrow } from './PopoverArrow';

interface PopoverContainerProps extends PopoverContentProps {
  withPortal?: boolean;
  showArrow?: boolean;
}

export const PopoverContainer = React.forwardRef(
  (
    {
      withPortal = true,
      showArrow = true,
      children,
      ...contentProps
    }: PopoverContainerProps,
    forwardedRef,
  ) => {
    const content = (
      <PopoverContent ref={forwardedRef} {...contentProps}>
        {showArrow && <PopoverArrow />}
        {children}
      </PopoverContent>
    );

    return withPortal ? <PopoverPortal>{content}</PopoverPortal> : content;
  },
);
