import * as React from 'react';
import { styled, COMMON } from '@mentimeter/ragnar-styled';
import { Content } from '@radix-ui/react-popover';
import type { ComponentProps } from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';

const PopoverContentRadixStyled = styled(
  Content,
  {
    displayName: 'PopoverContentRadixStyled',
  },
  true,
  [
    'forceMount',
    'side',
    'sideOffset',
    'align',
    'alignOffset',
    'avoidCollisions',
    'collisionBoundary',
    'collisionPadding',
    'arrowPadding',
    'sticky',
    'hideWhenDetached',
  ],
)(
  ...COMMON,
  'kosmosBorderRadius',
  'kosmosSpacing',
  'layout',
  'flexbox',
  'position',
);

export type PopoverContentProps = ComponentProps<
  typeof PopoverContentRadixStyled
>;

export const PopoverContent = React.forwardRef(
  ({ className, ...props }: PopoverContentProps, forwardedRef) => {
    const defaultClasses = [
      'flex',
      'flex-col',
      'items-stretch',
      'overflow-hidden',
      'max-w-[calc(100vw-theme(spacing.4))]',
      'max-h-[calc(100vw-theme(spacing.4))]',
      'sm:max-w-[calc(100vw-2*theme(spacing.16))]',
      'sm:max-h-[calc(100vw-2*theme(spacing.16))]',
      'min-w-[200px]',
      'bg-surface',
      'rounded-lg',
      'shadow-[0_3px_15px_var(--palette-black-alpha-400)]',
      'z-modal',
      'focus:outline-none',
      'data-[side="bottom"]:motion-safe:animate-fade-in-bottom',
      'data-[side="top"]:motion-safe:animate-fade-in-top',
      'data-[side="left"]:motion-safe:animate-fade-in-left',
      'data-[side="right"]:motion-safe:animate-fade-in-right',
    ];
    return (
      <PopoverContentRadixStyled
        ref={forwardedRef}
        collisionPadding={16}
        arrowPadding={4}
        sideOffset={1}
        className={cn(defaultClasses, className)}
        {...props}
      />
    );
  },
);
