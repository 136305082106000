import React, { type ComponentProps } from 'react';
import { styled, COMMON } from '@mentimeter/ragnar-styled';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { Arrow } from '@radix-ui/react-popover';

const PopoverArrowRadixStyled = styled(
  Arrow,
  {
    displayName: 'PopoverArrowRadixStyled',
  },
  true,
)(...COMMON);

export type PopoverArrowProps = ComponentProps<typeof PopoverArrowRadixStyled>;

export const PopoverArrow = React.forwardRef(
  (props: PopoverArrowProps, ref) => {
    const { className, ...rest } = props;
    return (
      <PopoverArrowRadixStyled
        ref={ref}
        className={cn('fill-[--color-surface]', className)}
        {...rest}
      />
    );
  },
);
