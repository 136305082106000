import { useContext } from 'react';
import {
  I18nContext,
  useTranslation as I18NextUseTranslation,
} from 'react-i18next';
import { useTranslations as NextIntlUseTranslations } from 'next-intl';

// temporary solution since react-i18next and next-intl has different signatures
export type TFunction = (
  key: string,
  params?: Record<string, string | number | undefined | null>,
) => string;

export function useTranslations(namespace: string): TFunction {
  try {
    const i18nContext = useContext(I18nContext); // Check if react-i18next context is available

    if (i18nContext) {
      // Otherwise, assume react-i18next is in use
      const { t } = I18NextUseTranslation(namespace);
      return t;
    }
  } catch (_) {
    // react-i18next context is not found, falling back to next-intl
  }

  // If in next-intl context (no I18nContext)
  return NextIntlUseTranslations(namespace);
}
