/* eslint-disable menti-react/filename-convention--jsx */
'use client';
import * as React from 'react';
import { Box, type BoxT } from '@mentimeter/ragnar-ui';
import { Theme } from '@mentimeter/ragnar-ui';
import type { ThemeT } from '../Theme';
import { CONTENTFUL_THEMES } from '../Theme';
import { Pattern } from '../visuals/Pattern';

export type SectionT = BoxT & ThemeT;

export const Section = React.forwardRef<HTMLDivElement, SectionT>(
  ({ theme, ...rest }, ref) => {
    const generalStyling: BoxT = {
      px: [4, 'space12', 6, 6],
      py: [4, 'space12', 'space18', 5],
      width: '100%',
      alignItems: 'center',
      as: 'section',
      overflow: 'hidden',
    };
    if (!theme) {
      return <Box ref={ref} {...generalStyling} {...rest} />;
    } else if (theme.colors.includes('pattern')) {
      return (
        <Pattern patternColor={theme.colors}>
          <Theme theme={CONTENTFUL_THEMES[theme.colors]}>
            <Box ref={ref} {...generalStyling} {...rest} />
          </Theme>
        </Pattern>
      );
    } else {
      return (
        <Theme theme={CONTENTFUL_THEMES[theme.colors]}>
          <Box bg="bg" ref={ref} {...generalStyling} {...rest} />
        </Theme>
      );
    }
  },
);

export const Wide = (props: BoxT) => (
  <Box maxWidth="1200px" width="100%" {...props} />
);

export const Narrow = (props: BoxT) => (
  <Box maxWidth="800px" width="100%" {...props} />
);

export const Limit = (props: BoxT) => (
  <Box maxWidth="40em" width="100%" {...props} />
);

type GridT = BoxT & {
  col: number;
  mobileCol?: number;
  gutter: number;
  children: Array<any>;
};

// Add alignItems="stretch" on the parent container for the grid to work as expected in all browsers
export const Grid = ({
  col,
  mobileCol = 1,
  gutter,
  children,
  ...rest
}: GridT) => {
  return (
    <Box mx={-gutter} width="100%">
      <Box flexDirection="row" flexWrap="wrap" width="100%" {...rest}>
        {React.Children.map(children, (c) => {
          return (
            <Box width={[1 / mobileCol, 1 / col]} px={gutter}>
              {c}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
