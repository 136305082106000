import { useState, useEffect } from 'react';
import { getReferral } from './referral';

export function useReferral() {
  // return empty string for server side rendering
  const [referral, setReferral] = useState('');

  useEffect(() => {
    // get window location on client side rendering`
    const params = new URLSearchParams(window.location.search);
    const referral = params.get('referral');

    setReferral(getReferral(window.location.pathname, referral));
  }, []);

  return referral;
}
