'use client';
import * as React from 'react';
import { Box, Clickable, Text, Collapsable } from '@mentimeter/ragnar-ui';
import { ChevronRightIcon } from '@mentimeter/ragnar-visuals';
import type { NextPage } from 'next';
import { NavLink, type NavLinkT } from './NavLink';
import { isMenuMultiAction, type MenuAction } from './';

interface Props {
  action: MenuAction | undefined;
  setShowMenu: (value: boolean) => void;
  overviewLabel?: string | undefined;
  openDropdownLabel?: string | undefined;
}

export type Action = Omit<NavLinkT, 'onClick'> & {
  name: string;
  onClick?: () => void;
};

interface SubActionsProps {
  action: MenuAction;
  overviewLabel: string;
  openDropdownLabel: string;
  setShowMenu: (value: boolean) => void;
}

const Actions = ({
  action,
  overviewLabel,
  openDropdownLabel,
  setShowMenu,
}: SubActionsProps) => {
  const [show, setShow] = React.useState(false);

  if (!action) return null;

  if (isMenuMultiAction(action)) {
    return (
      <>
        <Clickable
          aria-label={openDropdownLabel}
          onClick={() => setShow((prevShow) => !prevShow)}
          flexDirection="row"
          alignItems="center"
          extend={() => ({
            '@media (hover: hover)': {
              ':hover': {
                opacity: 1,
              },
            },
            ':active': {
              opacity: 1,
            },
          })}
        >
          <Text fontSize="150" fontWeight="semiBold" color="text">
            {action.name}
          </Text>
          <Box
            ml="space2"
            extend={({ theme }) => ({
              transition: `transform ${theme.durations[1]}s ease`,
              transform: show ? 'rotate(90deg)' : 'rotate(0)',
            })}
          >
            <ChevronRightIcon size={4} />
          </Box>
        </Clickable>
        <Box gap="space2">
          {action.subActions?.map((row) => (
            <Collapsable key={row.name} show={show} aria-expanded={show}>
              <Box ml="space8">
                <Actions
                  action={row}
                  overviewLabel={overviewLabel}
                  openDropdownLabel={openDropdownLabel}
                  setShowMenu={setShowMenu}
                />
              </Box>
            </Collapsable>
          ))}
        </Box>
      </>
    );
  }

  return (
    <>
      <Clickable
        aria-label={openDropdownLabel}
        onClick={() => setShow((prevShow) => !prevShow)}
        flexDirection="row"
        alignItems="center"
        extend={() => ({
          '@media (hover: hover)': {
            ':hover': {
              opacity: 1,
            },
          },
          ':active': {
            opacity: 1,
          },
        })}
      >
        <Text fontSize="150" fontWeight="semiBold" color="text">
          {action.name}
        </Text>
        <Box
          ml="space2"
          extend={({ theme }) => ({
            transition: `transform ${theme.durations[1]}s ease`,
            transform: show ? 'rotate(90deg)' : 'rotate(0)',
          })}
        >
          <ChevronRightIcon size={4} />
        </Box>
      </Clickable>
      <Collapsable show={show} aria-expanded={show}>
        <Box as="ul">
          {action.subActions?.map(({ href, name, ...linkProps }) => (
            <NavLink
              {...linkProps}
              onClick={() => {
                setShowMenu(false);
                action.onClick?.();
              }}
              active={false}
              mb="space4"
              ml="space8"
              key={name}
              fontSize="150"
              extend={() => ({
                '&:last-child': {
                  marginBottom: 0,
                },
              })}
            >
              {name}
            </NavLink>
          ))}
          {action.href && (
            <NavLink
              href={action.href}
              onClick={() => {
                setShowMenu(false);
                action.onClick?.();
              }}
              mb="space4"
              ml="space8"
              fontSize="150"
              active={false}
              prefetch={action.prefetch ?? null}
              tabIndex={show ? 0 : -1} // If menu is hidden, links should not be focusable
            >
              {overviewLabel}
            </NavLink>
          )}
        </Box>
      </Collapsable>
    </>
  );
};

const ActionCollapsable: NextPage<Props> = ({
  action,
  setShowMenu,
  overviewLabel = 'Overview',
  openDropdownLabel = 'Open dropdown',
}: Props) => {
  return (
    <Box mb="space4" overflow="hidden">
      {action?.subActions ? (
        <Actions
          action={action}
          overviewLabel={overviewLabel}
          openDropdownLabel={openDropdownLabel}
          setShowMenu={setShowMenu}
        />
      ) : (
        <NavLink
          fontSize="150"
          href={action?.href}
          active={false}
          onClick={() => {
            setShowMenu(false);
            action?.onClick?.();
          }}
        >
          {action?.name}
        </NavLink>
      )}
    </Box>
  );
};

export default ActionCollapsable;
