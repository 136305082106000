const REGISTRATION_HOMEPAGE = 'homepage';

export function getReferral(pathname: string, referral: string | null): string {
  if (pathname === '/' || referral === '') {
    return REGISTRATION_HOMEPAGE;
  }

  // Remove the first slash, so we don't ruin data for the old paths
  // that only contained the first sub-path without any slashes
  return referral ?? (pathname || '').split('/').filter(Boolean).join('/');
}
